import {
  DomainContext,
  DomainContextProps,
} from 'context/domain/domain-context';
import { LoginMethod } from 'globals/constants';
import { BenefitType } from '@epassi/ui-commons/enums';
import { IntlLanguage } from 'i18n';
import localStorageService, { DOMAIN_KEY } from 'local-storage/local-storage';
import { Country, Domain } from 'namespace/common';
import { ReactNode } from 'react';
import { isDevEnv } from 'utils/environment';

export const se: DomainContextProps = {
  languages: [IntlLanguage.en, IntlLanguage.sv],
  defaultLanguage: IntlLanguage.sv,
  country: Country.SE,
  benefits: [BenefitType.SPORT, BenefitType.BICYCLE],
  defaultBenefit: BenefitType.SPORT,
  mapCenter: {
    lat: 59.334591,
    lng: 18.06324,
  },
  countryCallingCode: '+46',
  domain: Domain.SE,
  defaultLoginMethod: LoginMethod.BANK_ID,
};

export const fi: DomainContextProps = {
  languages: [IntlLanguage.en, IntlLanguage.fi, IntlLanguage.sv],
  defaultLanguage: IntlLanguage.fi,
  country: Country.FI,
  benefits: [
    BenefitType.LUNCH,
    BenefitType.SPORT,
    BenefitType.CULTURE,
    BenefitType.HEALTH_2,
    BenefitType.TRAVEL,
    BenefitType.BICYCLE,
  ],
  defaultBenefit: BenefitType.LUNCH,
  mapCenter: {
    lat: 60.192059,
    lng: 24.945831,
  },
  countryCallingCode: '+358',
  domain: Domain.FI,
};

export const com: DomainContextProps = {
  languages: [IntlLanguage.en, IntlLanguage.fi, IntlLanguage.sv],
  defaultLanguage: IntlLanguage.en,
  country: undefined,
  benefits: [
    BenefitType.LUNCH,
    BenefitType.SPORT,
    BenefitType.CULTURE,
    BenefitType.HEALTH_2,
    BenefitType.TRAVEL,
    BenefitType.BICYCLE,
  ],
  defaultBenefit: BenefitType.LUNCH,
  mapCenter: {
    lat: 60.192059,
    lng: 24.945831,
  },
  countryCallingCode: '+358',
  domain: Domain.COM,
};

export interface DomainWrapperProps {
  domain?: string;
  children?: ReactNode;
}

const domainToSettings = {
  [Domain.FI]: fi,
  [Domain.SE]: se,
  [Domain.COM]: com,
} as const;

const DomainWrapper = ({ domain, children }: DomainWrapperProps) => {
  const cypressDomain = localStorageService.get('cypress-test-domain');
  const localDomain = isDevEnv && window.ENV.REACT_APP_DOMAIN;
  const selectedDomain =
    cypressDomain ||
    localDomain ||
    domain ||
    window.location.hostname.split('.').pop();
  const settings =
    selectedDomain && domainToSettings[selectedDomain as Domain]
      ? domainToSettings[selectedDomain as Domain]
      : com;
  if (typeof selectedDomain === 'string') {
    localStorageService.set(DOMAIN_KEY, selectedDomain);
  }
  return (
    <DomainContext.Provider
      value={{
        ...settings,
      }}
    >
      {children}
    </DomainContext.Provider>
  );
};
export default DomainWrapper;
